import "./App.css";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import Services from "./components/Services";
import Home from "./Pages/Home";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollTo) {
      scroller.scrollTo(location.state.scrollTo, { smooth: true, duration: 500 });
    }
  }, [location]);
  return (
    <div>
      <main>
        <Box component="main">
          <Home />
        </Box>
        <Services />
        <AboutUs />
        <Contact />
      </main>
    </div>
  );
}

export default App;
