import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import App from "./App";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import SerivcesDetails from "./Pages/ServicesDetails";
import TermsAndCondition from "./Pages/TermsAndCondition";
import Navigation from "./components/Navigation";
import Layout from "./Pages/Layout";
const Routess = () => {
  return (
    <>
      {/* <Navigation /> */}
      {/* <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/ServicesDetails" element={<SerivcesDetails />} />
          <Route path="/TermsAndConditions" element={<TermsAndCondition />} />
        </Routes>
      </Router> */}
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <App />
              </Layout>
            }
          />
          <Route
            path="/PrivacyPolicy"
            element={
              <Layout>
                <PrivacyPolicy />
              </Layout>
            }
          />
          <Route
            path="/ServicesDetails"
            element={
              <Layout>
                <SerivcesDetails />
              </Layout>
            }
          />
          <Route
            path="/TermsAndConditions"
            element={
              <Layout>
                <TermsAndCondition />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default Routess;
