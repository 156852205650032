import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { TextField, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { FormControlLabel, Checkbox, Link } from "@mui/material";
export default function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const form = useRef();

  const submit = async (e) => {
    e.preventDefault(); // Prevents the form from reloading the page

    emailjs
      .sendForm(
        "service_x1m6ilf",
        "template_2l3lpgo",
        form.current,
        "a4po6X2O4htj_mvAE"
      )
      .then(
        () => {
          toast.success("Successfully Submitted", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        },
        (error) => {
          toast.error("Failed", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
  };

  return (
    <Box sx={{ width: { xs: "90%", md: "15%" } }}>
      <ToastContainer />
      <Button
        onClick={toggleDrawer(true)}
        variant="contained"
        sx={{
          width: "100%",
          whiteSpace: "nowrap",
          background: "black",
          padding: "10px 50px",
          fontSize: "20px",
          "&:hover": {
            backgroundColor: "#2F3645",
          },
          borderRadius: "10px",
        }}>
        Get a Quote
      </Button>
      <Drawer anchor="left" open={open}>
        <Box sx={{ width: { xs: 350, md: 500 } }} role="presentation">
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "25px",
              textAlign: "center",
              paddingTop: "30px",
            }}>
            Get a Quote
          </Typography>
          <CancelIcon
            onClick={toggleDrawer(false)}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "black",
              fontSize: "40px",
              cursor: "pointer",
            }}
          />
          <form ref={form} onSubmit={submit} style={{ width: "100%" }}>
            <Box
              sx={{
                paddingTop: "30px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                width: "80%",
                margin: "0 auto",
              }}>
              <TextField
                id="outlined-password-input"
                label="Please Enter Contact Name"
                required
                name="name"
                sx={{ width: "100%" }}
              />
              <TextField
                id="outlined-password-input"
                label="Please Enter MC Number"
                required
                name="mc-number"
                sx={{ width: "100%" }}
              />
              <TextField
                id="outlined-password-input"
                required
                label="Please Enter Truck Type"
                name="truck-type"
                sx={{ width: "100%" }}
              />
              <TextField
                id="outlined-password-input"
                label="Please Enter Trailer Type"
                name="trailer-type"
                sx={{ width: "100%" }}
              />
              <TextField
                id="outlined-password-input"
                label="Please Enter Phone Number"
                name="phone"
                type="phone-number"
                required
                sx={{ width: "100%" }}
              />
              <TextField
                id="outlined-password-input"
                label="Services you are looking for"
                name="service"
                multiline
                rows={4}
                sx={{ width: "100%" }}
              />

              <FormControlLabel
                sx={{ width: "100%", display: "flex", gap: 1 }}
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    color="secondary"
                    sx={{
                      background: "black",
                      color: "black",
                      "&.Mui-checked": {
                        color: "black",
                      },
                      "&:hover": {
                        backgroundColor: "#000000",
                      },
                    }}
                  />
                }
                label={
                  <Typography variant="body2" color="textSecondary">
                    By checking this box, you agree to be contacted via phone
                    and email regarding your interest in our products and
                    services. We will treat your data in accordance with our{" "}
                    <Link href="/PrivacyPolicy" color="primary">
                      privacy policy
                    </Link>
                    .
                  </Typography>
                }
              />
              <Button
                variant="contained"
                disabled={!checked}
                type="submit"
                value="Send"
                sx={{
                  background: "black",
                  marginTop: "10px",
                  width: "50%",
                  padding: "10px 0",
                  "&:hover": {
                    backgroundColor: "#2F3645",
                  },
                }}>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Drawer>
    </Box>
  );
}
