import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../assets/Logo.png";

const TermsAndCondition = () => {
  return (
    // <Box component="div" sx={{ background: "white", height: "100%" }}>
    //   <Box>
    //     <Link to="/">
    //       <img src={Logo} width={100} height={100} alt="" />
    //     </Link>
    //   </Box>
    //   <Box
    //     sx={{
    //       paddingLeft: "400px",
    //       paddingRight: "400px",
    //       textAlign: "center",
    //       paddingBottom: "100px",
    //     }}>
    //     <Typography sx={{ fontSize: "40px" }}> Terms and Conditions</Typography>
    //     <Box>
    //       <Typography variant="body1" paragraph>
    //         Welcome to HexaLinks! These Terms and Conditions outline the rules
    //         and regulations for using our services, including truck dispatching,
    //         factoring, and company formation. By accessing or using our
    //         services, you agree to comply with these terms.
    //       </Typography>
    //       <Typography variant="h6" gutterBottom>
    //         1. Service Agreement
    //       </Typography>
    //       <Typography variant="body1" paragraph>
    //         Our services are provided to assist your business operations. You
    //         agree to provide accurate information and use our services
    //         responsibly. Misuse of services may lead to termination.
    //       </Typography>
    //       <Typography variant="h6" gutterBottom>
    //         2. Payment Policy
    //       </Typography>
    //       <Typography variant="body1" paragraph>
    //         Payments for services must be completed as per agreed terms. All
    //         invoices are handled transparently, and timely payments ensure
    //         uninterrupted service.
    //       </Typography>
    //       <Typography variant="h6" gutterBottom>
    //         3. Intellectual Property
    //       </Typography>
    //       <Typography variant="body1" paragraph>
    //         All content, including materials and software, provided by us is the
    //         intellectual property of HexaLinks and must not be reused without
    //         permission.
    //       </Typography>
    //       <Typography variant="h6" gutterBottom>
    //         4. Liability Limitations
    //       </Typography>
    //       <Typography variant="body1" paragraph>
    //         We are not liable for unforeseen events such as delays caused by
    //         weather, regulations, or third-party actions. We strive to resolve
    //         issues promptly.
    //       </Typography>
    //       <Typography variant="h6" gutterBottom>
    //         5. Changes to Terms
    //       </Typography>
    //       <Typography variant="body1" paragraph>
    //         We reserve the right to modify these terms at any time. Continued
    //         use of our services implies acceptance of the updated terms. For
    //         questions, contact us at dispatch@hexalinksllc.com.
    //       </Typography>
    //     </Box>
    //   </Box>
    // </Box>
    <Box
      component="div"
      sx={{ background: "white", paddingTop: "100px", minHeight: "100vh" }}>
      {/* Logo Section */}
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-start" },
          padding: { xs: "20px", sm: "20px 50px" },
        }}>
        <Link to="/">
          <img
            src={Logo}
            alt="Logo"
            style={{
              width: "80px",
              height: "80px",
              objectFit: "contain",
            }}
          />
        </Link>
      </Box> */}

      {/* Terms and Conditions Content */}
      <Box
        sx={{
          paddingLeft: { xs: "20px", sm: "50px", md: "400px" },
          paddingRight: { xs: "20px", sm: "50px", md: "400px" },
          textAlign: "center",
          paddingBottom: "50px",
        }}>
        <Typography
          sx={{
            fontSize: { xs: "28px", sm: "36px", md: "40px" },
            marginBottom: "20px",
          }}>
          Terms and Conditions
        </Typography>
        <Box>
          <Typography variant="body1" paragraph>
            Welcome to HexaLinks! These Terms and Conditions outline the rules
            and regulations for using our services, including truck dispatching,
            factoring, and company formation. By accessing or using our
            services, you agree to comply with these terms.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: { xs: "18px", sm: "20px" },
              marginTop: "20px",
            }}>
            1. Service Agreement
          </Typography>
          <Typography variant="body1" paragraph>
            Our services are provided to assist your business operations. You
            agree to provide accurate information and use our services
            responsibly. Misuse of services may lead to termination.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: { xs: "18px", sm: "20px" },
              marginTop: "20px",
            }}>
            2. Payment Policy
          </Typography>
          <Typography variant="body1" paragraph>
            Payments for services must be completed as per agreed terms. All
            invoices are handled transparently, and timely payments ensure
            uninterrupted service.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: { xs: "18px", sm: "20px" },
              marginTop: "20px",
            }}>
            3. Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph>
            All content, including materials and software, provided by us is the
            intellectual property of HexaLinks and must not be reused without
            permission.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: { xs: "18px", sm: "20px" },
              marginTop: "20px",
            }}>
            4. Liability Limitations
          </Typography>
          <Typography variant="body1" paragraph>
            We are not liable for unforeseen events such as delays caused by
            weather, regulations, or third-party actions. We strive to resolve
            issues promptly.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: { xs: "18px", sm: "20px" },
              marginTop: "20px",
            }}>
            5. Changes to Terms
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to modify these terms at any time. Continued
            use of our services implies acceptance of the updated terms. For
            questions, contact us at dispatch@hexalinksllc.com.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TermsAndCondition;
